//
//
//
//
//
//
//
//
//
//
//
//
//

import { getEntityMetricsListArray } from "@/utils/enums";
import { dateRangeFromDate, today, dateRangeBoundaries, default as RangeCalendar } from "@/components/RangeCalendar.vue";
import AppSelect from "@/components/AppSelect.vue";
import Checkbox from "@/components/Checkbox";
export default {
  components: {
    AppSelect,
    RangeCalendar,
    Checkbox
  },
  props: {
    entity: {
      type: String,
      required: true
    },
    calendarBoundaries: {
      type: Array,
      required: true
    },
    initialState: {
      type: Object,
      default: null
    }
  },
  data() {
    let metric_items = getEntityMetricsListArray(this.entity, this.$route.params.mp);

    //&: 2023-04-25: не показываем пункт меню '收入损失'
    if (this.entity === "category") {
      let idx = metric_items.findIndex(m => m.id === "losses");
      if (idx > -1) metric_items.splice(idx, 1);
    }
    return {
      metric_items,
      metric_model: metric_items[0],
      fbs: false,
      calendar: null
    };
  },
  async mounted() {
    if (this.initialState) {
      const {
        config = {}
      } = this.initialState;
      const {
        metric,
        fbs,
        date,
        date2
      } = config;
      if ([metric, fbs, date, date2].some(i => i === undefined)) {
        return;
      }
      this.metric_model = this.metric_items.find(i => i.id === metric) || this.metric_model;
      this.fbs = !!fbs;
      this.calendar = [date, date2];
      this.onSubmit();
    }
  },
  methods: {
    onSubmit() {
      var _this$metric_model;
      const actions = {
        category: "rating/getRatingCategories",
        product: "rating/getRatingProducts",
        brand: "rating/getRatingBrands",
        seller: "rating/getRatingSellers"
      };
      this.$emit("submit", {
        entity: this.entity,
        action: actions[this.entity],
        config: {
          metric: (_this$metric_model = this.metric_model) === null || _this$metric_model === void 0 ? void 0 : _this$metric_model.id,
          fbs: this.fbs ? 1 : 0,
          date: this.calendar[0],
          date2: this.calendar[1]
        }
      });
    }
  },
  computed: {},
  watch: {
    calendarBoundaries: {
      immediate: true,
      handler(v) {
        var _this$calendarBoundar;
        if (!v) {
          return;
        }
        const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
        const weekRange = dateRangeFromDate(lastAvailableDate, 7);
        this.calendar = dateRangeBoundaries([weekRange[0], weekRange[1]], this.calendarBoundaries);
      }
    }
  }
};