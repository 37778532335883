//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getEntityMetricsListArray } from "@/utils/enums";
import { dateRangeFromDate, today } from "@/components/RangeCalendar.vue";
import AppSelect from "@/components/AppSelect.vue";
import Checkbox from "@/components/Checkbox";
export default {
  components: {
    AppSelect,
    Checkbox
  },
  props: {
    entity: {
      type: String,
      required: true
    },
    calendarBoundaries: {
      type: Array,
      required: true
    },
    initialState: {
      type: Object,
      default: null
    }
  },
  data() {
    const metric_items = getEntityMetricsListArray(this.entity);
    const days_items = [{
      id: 3,
      title: "3天"
    }, {
      id: 7,
      title: "7天"
    }, {
      id: 14,
      title: "14天"
    }, {
      id: 30,
      title: "30天"
    }];
    return {
      metric_items,
      metric_model: metric_items[0],
      days_items,
      days_model: days_items[1],
      fbs: false
    };
  },
  async mounted() {
    if (this.initialState) {
      const {
        config = {}
      } = this.initialState;
      const {
        metric,
        fbs,
        days
      } = config;
      if ([metric, fbs, days].some(i => i === undefined)) {
        return;
      }
      this.metric_model = this.metric_items.find(i => i.id === metric) || this.metric_model;
      this.fbs = !!fbs;
      this.days_model = this.days_items.find(i => i.id === days) || this.days_model;
      await this.$nextTick();
      this.onSubmit();
    }
  },
  methods: {
    onSubmit() {
      var _this$calendarBoundar, _this$days_model, _this$metric_model, _this$days_model2;
      const actions = {
        product: "rating/getNewProducts"
      };
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const dateRange = dateRangeFromDate(lastAvailableDate, ((_this$days_model = this.days_model) === null || _this$days_model === void 0 ? void 0 : _this$days_model.id) || 30);
      this.$emit("submit", {
        entity: this.entity,
        action: actions[this.entity],
        config: {
          metric: (_this$metric_model = this.metric_model) === null || _this$metric_model === void 0 ? void 0 : _this$metric_model.id,
          days: (_this$days_model2 = this.days_model) === null || _this$days_model2 === void 0 ? void 0 : _this$days_model2.id,
          fbs: this.fbs ? 1 : 0,
          date: dateRange[0],
          date2: dateRange[1]
        }
      });
    }
  },
  computed: {}
};