//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CalendarMixin from "@/mixins/Calendar";
import AppTabs from "@/components/AppTabs.vue";
import AppSelect from "@/components/AppSelect.vue";
import MainTitle from "@/components/MainTitle.vue";
import MainDescription from "@/components/MainDescription.vue";
import { getEntitiesList, getEntityMetricsList } from "@/utils/enums";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import CategoriesTable from "@/components/Rating/CategoriesTable.vue";
import ProductsTable from "@/components/Rating/ProductsTable.vue";
import BrandsTable from "@/components/Rating/BrandsTable.vue";
import SellersTable from "@/components/Rating/SellersTable.vue";
const tableComponents = {
  category: CategoriesTable,
  product: ProductsTable,
  brand: BrandsTable,
  seller: SellersTable
};
import RatingForm from "@/components/Rating/RatingForm.vue";
import NewForm from "@/components/Rating/NewForm.vue";
import UpsDownsForm from "@/components/Rating/UpsDownsForm.vue";
import ConstructorForm from "@/components/Rating/ConstructorForm.vue";
const formComponents = {
  rating: RatingForm,
  new: NewForm,
  upsdowns: UpsDownsForm,
  constructor: ConstructorForm
};
export default {
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Wildberries 市场上的评级和最高销量: 产品, 新商品, 类别和品牌" : "OZON 市场上的评级和最高销量: 产品, 新商品, 类别和品牌",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder 是 Wildberries 的详细分析服务. 评级和最高销量 - 一键完成!" : "SalesFinder - OZON 详细分析服务. 评级和最高销量 - 一键完成!"
      }]
    };
  },
  data() {
    var _initialState;
    let initialState = this.$route.query._state;
    if (initialState) {
      try {
        initialState = JSON.parse(initialState);
      } catch (e) {
        initialState = null;
      }
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          _state: undefined
        }
      });
    }
    const tabs = [{
      id: "categories",
      title: "产品类别",
      entity: "category",
      form: "rating"
    }, {
      id: "products",
      title: "产品",
      entity: "product",
      form: "rating"
    }, {
      id: "new",
      title: "新东西",
      entity: "product",
      form: "new"
    }, {
      id: "brands",
      title: "品牌",
      entity: "brand",
      form: "rating"
    }, {
      id: "sellers",
      title: "卖家",
      entity: "seller",
      form: "rating"
    }

    /* //&: 2022-02-14 : api_CH пока не реализован
            {
                id: 'ups',
                title: 'Взлеты',
                form: 'upsdowns',
                type: 'ups'
            },
            {
                id: 'downs',
                title: 'Падения',
                form: 'upsdowns',
                type: 'downs'
            },
            {
                id: 'constructor',
                title: 'Конструктор',
                form: 'constructor'
            }
            */];

    return {
      tabs,
      tabs_active: null,
      show_table: false,
      form_data: null,
      tableKey: 0,
      size: 5000,
      initialState: (_initialState = initialState) === null || _initialState === void 0 ? void 0 : _initialState.form
    };
  },
  methods: {
    getTableId() {
      return this._uid;
    },
    onSubmit({
      entity,
      action,
      config
    }) {
      this.$access.hasOrThrow("ratings");
      this.form_data = {
        entity,
        action,
        config
      };
    },
    analyze() {
      this.show_table = true;
    }
  },
  computed: {
    track() {
      const entity = this.form_data.entity;
      let entityText = getEntitiesList("manyGenitive", "lower")[entity];
      if (this.tabs_active.form === "new") {
        entityText = "新产品";
      }
      let title = `评级 ${entityText}`;
      const metric = this.form_data.config.metric;
      if (metric) {
        title += `通过 ${getEntityMetricsList(entity, "dative")[metric]}`;
      }
      if (this.tabs_active.form === "constructor") {
        title = `按选定条件列出${entityText}`;
      }
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / 评级 ${entityText}`,
        name: title,
        form: this.form_data,
        date: this.form_data.config.date,
        date2: this.form_data.config.date2
      };
    },
    fileName() {
      return `${this.track.name} ${this.track.date} ${this.track.date2}`;
    },
    formComponentBindings() {
      if (!this.tabs_active) {
        return;
      }
      return {
        is: formComponents[this.tabs_active.form],
        entity: this.tabs_active.entity,
        type: this.tabs_active.type,
        calendarBoundaries: this.calendarBoundaries
      };
    },
    tableComponentBindings() {
      if (!this.form_data) {
        return null;
      }
      return {
        is: tableComponents[this.form_data.entity],
        action: this.form_data.action,
        reportSettings: {
          ...this.form_data.config,
          size: this.size,
          mp: this.$route.params.mp
        }
      };
    },
    tableRouteParams() {
      return {
        mp: this.$route.params.mp
      };
    },
    tableTitle() {
      if (!this.form_data) {
        return;
      }
      const entity = this.form_data.entity;
      let entityText = getEntitiesList("manyGenitive", "lower")[entity];
      if (this.tabs_active.form === "new") {
        entityText = "新产品";
      }
      if (this.tabs_active.form === "constructor") {
        return `按选定条件列出${entityText}`;
      }
      let result = `TOP-${this.size} ${entityText}`;
      const metric = this.form_data.config.metric;
      if (metric) {
        result += `通过 ${getEntityMetricsList(entity, "dative")[metric]}`;
      }
      return result;
    },
    ready() {
      return !!this.calendarBoundaries;
    }
  },
  watch: {
    tabs_active() {
      this.form_data = null;
    },
    tableComponentBindings() {
      this.tableKey++;
    }
  },
  components: {
    AppTabs,
    AppSelect,
    MainTitle,
    MainDescription,
    DataTableActions
  }
};